import React from 'react';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import { imageWrapper } from './products.module.scss';
import ProductCard from '../../components/elements/ProductCard';
import { StaticImage } from 'gatsby-plugin-image';
import { sites } from '../../Data/pagesTexts';
import { Helmet } from 'react-helmet';

function Sites() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{'Olkis |  αντικουνουπικές σίτες'}</title>
                <html lang={'el'} />
                <meta
                    name="description"
                    content={'Οι αντικουνουπικές σίτες κατασκευάζονται από ύφασμα άριστης ποιότητας και ανάλογα με τις ανάγκες μπορούν να διατεθούν με τους εξής μηχανισμούς. Ορίζοντας κίνησης, κάθετης κίνησης, ανοιγόμενες, συρόμενες / επάλληλες δίφυλλες, σταθερά τελάρα.'}
                />
            </Helmet>
            <Layout>
                <MainView>
                    {/* <About backgroundColor={false} header={rollerBlindsData.headerInfo.header} text={rollerBlindsData.headerInfo.text} /> */}
                    <ProductCard header={sites.header} text={sites.text}  >
                        <StaticImage className={imageWrapper} quality={70} layout='fullWidth' src='../../images/sites/sita.jpg' alt="asdasdasdas" />
                    </ProductCard>
                </MainView>
            </Layout>
        </>
    );
}

export default Sites;

const MainView = styled.div`
min-height: 100vh;
display: flex;
flex-direction:column;
align-items: center;
background: linear-gradient(360deg, #4d4d55 0%, #f5f5f5 100%);

`;